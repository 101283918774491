<template>
    <div class="page ">
        <full-page ref="fullpage" :options="options">
        <div class="section row works">
            <div class="col-md-3 works-left visible-md visible-lg hidden-xs hidden-sm">
              <SidebarWorks @setSubLink="setSubLink" @setLink="setLink" :activeLink="'how'" :activeSublink="activeHow" ></SidebarWorks>
            </div>
            <div class="col-md-9 works-right">
                <div class="works-content fade-in">
                    <NavigationWorks class="hidden-md hidden-lg visible-xs visible-sm" :enableNav="true" @setSubLink="setSubLink" @setLink="setLink" :activeLink="'how'" :activeSublink="activeHow" :arrArray="[0,1,2]"
                    />
                    <!-- <h2>{{translate[language].subtitle}}</h2> -->
                    <div v-show="activeHow == 0" class="fade-in "  style="padding:20px">
                        <h3> {{translate[language].slide1.title}} </h3>
                        <div v-html="translate[language].slide1.content"> </div>
                        <div class="works-comittee-container">
                            <div class="works-comittee-container-images" > 
                                <img src="/images/image-commitees.png" class="works-comitee animation delay-1 fade-in">
                                <img src="/images/image-line-invert.png" class="work-line animation delay-2 fade-in">
                                <img src="/images/image-line.png" class="work-line animation delay-2 fade-in">
                                <div class="works-shareholder-comittee animation delay-3 fade-in"> {{ translate[language].slide1.imageText }} </div>
                            </div>
                        </div>
                    </div>
                     <div v-show="activeHow == 1" class="fade-in" style="padding:20px">
                        <h3> {{translate[language].slide2.title}} </h3>
                        <div v-html="translate[language].slide2.content"> </div>
                         <div class="works-comittee-container">
                            <div class="works-comittee-container-images" > 
                                <img src="/images/image-commitees.png" class="works-comitee animation delay-1 fade-in">
                                <img src="/images/image-line.png" class="work-line animation delay-2 fade-in">
                                <img src="/images/image-line-invert.png" class="work-line animation delay-2 fade-in">
                                 <img src="/images/image-line-invert.png" class="work-line animation delay-2 fade-in" style=" top: 0; right: -20px; ">
                                <div class="works-tech-comittee-1 animation delay-3 fade-in" v-html="translate[language].slide2.imageText1"></div>
                                <div class="works-tech-comittee-2 animation delay-3 fade-in" v-html="translate[language].slide2.imageText2"></div>
                                <div class="works-tech-comittee-3 animation delay-3 fade-in" v-html="translate[language].slide2.imageText"></div>
                            </div>
                        </div> 
                    </div>
                    <div v-show="activeHow == 2" class="fade-in " style="padding:20px">
                    <h3>{{translate[language].slide3.title}} </h3>
                        <div v-html="translate[language].slide3.content"> </div>   
                        <div class="works-management-container center">
                            <img src="/images/image-commitee.png" class="works-comitee animation delay-1 fade-in">
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        </full-page>
    </div>
</template>
<script>
export default {
    data(){
        return {
            activeHow: 0,
            language : this.$cookies.get('language') == null ? 'english' : this.$cookies.get('language'),
            translate : {
                spanish: {
                    title: 'CÓMO FUNCIONA EL FONDO',
                    /** subtitle: 'control eficiente de nuestros INVERSIONISTAS', */
                    slide1: {
                        title: 'JUNTA DE ACCIONISTAS',
                        content: '<p>Analizarán cambios en los parámetros de inversión y Cambios en las atribuciones Del Comité Técnico  </p>' ,
                        imageText : "Todos los miembros"
                    },
                    slide2: {
                        title: 'COMITÉ TÉCNICO' ,
                        content :"<p> Aprobará inversiones que representen hasta 50% del fondo<br> Aprobará acuerdos de las partes relacionadas  </p> " ,
                        imageText : "20% equipo de <br>HAN Capital",
                        imageText1 : "20% miembros <br>independientes",
                        imageText2 : "60% miembros <br>inversionistas"
                    },
                    slide3: {
                        title: 'EQUIPO DIRECTIVO',
                        content:  "<p> PRESENTARÁ LAS PROPUESTAS DE INVERSIÓN PARA SER APROBADAS POR LOS SOCIOS GENERALES CUANDO NO EXCEDAN 10% DEL FONDO</p>  <p>Presentará propuestas de inversión al Comité Técnico y accionistas</p>",
                    }
                },
                english: {
                    title: 'HOW DOES THE FUND WORK',
                    /** subtitle: 'WITH CORPORATE GOVERNANCE AND STEWARDSHIP',*/
                    slide1: {
                        title: 'SHAREHOLDERS COMMITTEE',
                        content: '<p>THEY WILL LEAD AND ANALYZE CHANGES IN INVESTMENT PARAMETERS AND MODIFICATIONS IN ATTRIBUTIONS FROM THE TECHNICAL COMMITTEE </p>' ,
                        imageText : "Every member"
                    },
                    slide2: {
                        title: 'TECHNICAL COMMITTEE' ,
                        content :"<p> APPROVE INVESTMENTS THAT REPRESENT UP TO 50% OF THE FUND APPROVE AGREEMENTS OF THE RELATED PARTIES</p>" ,
                        imageText : "20% management <br> members",
                        imageText1 : "20% INDEPENDENT <br> MEMBERS",
                        imageText2 : "60% INVESTORS <br> MEMBERS"
                    },
                    slide3: {
                        title: 'MANAGEMENT',
                        content:  "<p> SUBMIT INVESTMENT PROPOSALS TO BE APPROVED BY GENERAL PARTNERS WHEN THEY DO NOT EXCEED 10% OF THE FUND</p>  <p>SUBMIT INVESTMENT PROPOSALS FOR THE APPROVAL OF THE TECHNICAL COMMITTEE AND SHAREHOLDERS</p>",
                    }
                },
            },
            options: {
                scrollBar: false,
                responsiveSlides: true,
                easingcss3 : 'cubic-bezier(0.455, 0.310, 0.120, 1.020)',
                licenseKey: '',
                scrollingSpeed : 1000,
                fitToSectionDelay: 2000,
                css3: true,
            },

        }
    },
    methods: {
        setLink( activeHow ){
            this.activeHow = activeHow
        },
        setSubLink( activeHow ){
            this.activeHow = activeHow
        },
        checkLanguage(){
            let self = this 
            setInterval( function(){
                self.language = ( self.$cookies.get('language') == null ? 'english' : self.$cookies.get('language')  )   // return value      
            }, 1000 )
        },
    },
    created(){
        this.checkLanguage()
    }
}
</script>

